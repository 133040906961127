export default
 {
    title: {
        ar: "مرحبا!",
        en: "Welcome!"
    },
    lead: {
        ar: "يمكنك الدخول لاستخدام الظام فور تسجيل دخولك باستخدام اسم المستخدم وكلمة المرور، اذا لم تكن تمتلك حسابا يمكنك انشاء عبر النقر على انشاء حساب باسفل الشاشة",
        en: "You can log in to use the system as soon as you log in using your username and password. If you do not have an account, you can create an account by clicking on Create an account at the bottom of the screen."
    },
    signupTitle: {
        ar: "تسجيل الدخول",
        en: "Sign in"
    },
    plsFill: {
        ar: "الرجاء ادخال بياناتك",
        en: "please fill your data"
    },
    userName: {
        ar: "اسم المستخدم",
        en: "username"
    },
    password: {
        ar: "كلمة المرور",
        en: "password"
    },
    signin: {
        ar: "تسجيل الدخول",
        en: "Sign in"
    },
    forgot:{
        ar: "نسيت كلمة المرور؟",
        en: "Forgot password?"
    },
    create: {
        ar: "انشاء حساب",
        en: "Create new account"
    }
    
}