import React from 'react';
import {
  Card,
  CardBody,
  CardHeader
} from 'reactstrap';

const DocsCard = ({ files, title }) => {

  const extenstionChecker = (el) => {
        
    const pdf = <i className="fas fa-file-pdf text-red"></i>;
    const word = <i className="fas fa-file-word text-blue"></i>;
    const powepoint = <i className="fas fa-file-powerpoint text-orange"></i>;
    const png = <i className="fas fa-file-image text-green"></i>;
    const excel = <i className="far fa-file-excel text-green"></i>
    const folder = <i className="fas fa-folder"></i>;
    const extension = el.substr(el.lastIndexOf('.') - el.length + 1);
    if (extension === 'pdf')
        return pdf;
    else if (extension === 'docx' || extension === 'dot')
        return word;
    else if (extension === 'pptx' || extension === 'ppt' || extension === 'ppsx')
        return powepoint;
    else if (extension === 'png')
        return png;
    else if (extension === 'xlsx' || extension === 'xlsm' || extension === 'xls' || extension === 'xlsb')
        return excel;
    else if (extension === 'dir')
        return folder
      
    return false;
  };

  const nameLength = (el, len) => {
    const name = el.split('/').at(-1);
    return name.length > len ? (name.substr(0, len) + '...') : name;
  }
  
  return (
    <Card>
      <CardHeader>
        <div className="d-flex justify-content-between">
          <h3 className={`mb-0 text-md-left`}>{title || "Attachments"}</h3>
        </div>
      </CardHeader>
      <CardBody>
        <div className="attachment d-flex flex-nowrap mb-5">
          {
            files.map((file, index) => (
                <div key={index} className='d-flex flex-column text-center mr-4 ml-4'>
                  <a href={file} target="_blank">
                    {extenstionChecker(file)}
                  </a>
                  <a href={file} target="_blank">{nameLength(file, 10)}</a>
                </div>
            ))
          }
        </div>
      </CardBody>
    </Card>
  )
}

export default DocsCard